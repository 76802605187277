<template>
  <admin>
    <page-header
      :back="{
        to: {
          name: 'dealers-account-maintenance.dealerusers.index',
        },
        text: trans('Dealer Users'),
      }"
    >
      <template v-slot:title><span v-text="trans('Edit Dealer User')"></span></template>
    </page-header>

    <div class="row">
      <div class="col-lg-9 col-12">
        <Details action="edit" v-if="show" :details="details" />
      </div>
    </div>
  </admin>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Details from "./partials/forms/Details.vue";

export default {
  components: {
    Details,
  },
  data() {
    return {
      show: false,
      dealer_id: null,
    };
  },
  computed: {
    ...mapGetters({
      details: "dealerUser/GET_DEALER_USER",
    }),
  },
  methods: {
    ...mapActions({
      getDealerUser: "dealerUser/getDealerUser",
    }),
  },
  async mounted() {
    await this.getDealerUser(this.$route.params.id);
    this.dealer_id = this.$route.params.dealer_id;
    this.show = true;
  },
};
</script>

<style></style>
